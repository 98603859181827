<template>
  <div id="back" class="section hero mt-0 p-0">
    <div id="filt">
      <div id="text-logo">
        <div id="head">
          <img src="./assets/FYD.svg" width="50px" />
          <h1 class="is-size-1">Follow Your Data</h1>
        </div>
        A digital librarian for FAIR data
        <div id="bottomfloat">Handelingenkamer Eerste kamer: Hans Kouwenhoven</div>
      </div>
    </div>
  </div>
  <div id="main" class="container">
    <section>
      <div class="tile">
        <div class="tile is-parent">
          <div class="tile is-child card" id="fair">
            <div class="card-header">
              <span class="card-header-title">Fair</span>
            </div>
            <div class="columns is-desktop">
              <div class="column">
                <div class="content">
                  <h3>Findable, Accessible, Interoperable and Reusable</h3>
                  <div class="card-label">
                    <img
                      src="./assets/FAIR.png"
                      width="60"
                      height="auto"
                      style="float: right"
                    />FAIR underlies many initiatives to make scientific data open and
                    accessible for reuse.
                  </div>
                  <hr />
                </div>
              </div>
              <div class="column">
                <div class="content">
                  While researchers generally support the principles of FAIR data
                  (Findable, Accessible, Interoperable, and Reusable), the practical
                  implementation can be complex and time-consuming. One of the reasons for
                  this struggle is the lack of standardized data organization and
                  documentation practices across different research disciplines and even
                  within the same discipline. Each researcher may have their own preferred
                  methods of organizing and documenting data, which can make it difficult
                  for others to interpret and utilize that data effectively.
                  <div class="mytag" @click="$refs.fairinfo.classList.add('is-active')">
                    <a>Read More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tile is-parent">
          <div class="tile is-child card" id="tools">
            <div class="card-header">
              <span class="card-header-title">Tools</span>
            </div>
            <div class="columns is-desktop">
              <div class="column">
                <div class="content">
                  <h3><br />Tools for fair</h3>
                  <div class="card-label">
                    <span class="bulma-fa-mixin"><i class="fa-solid fa-gear"></i></span
                    >Current tools available are not suitable for all kinds of data or
                    applicable in every research environment.
                  </div>
                  <hr />
                </div>
              </div>
              <div class="column">
                <div class="content">
                  Follow Your Data (FYD) is a framework to help individual labs organize
                  and catalog their data. It is an online archiving system on the intranet
                  of an institute and requires support from an ICT departement to
                  maintain. However, once setup it is easy to apply and will not interfere
                  with the access and usage of any type of research data. Moreover, you
                  can start making your data fair from the very start of a project with
                  minimal effort.
                  <div class="mytag" @click="$refs.fydinfo.classList.add('is-active')">
                    <a>Read More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tile">
        <div class="tile is-parent">
          <div class="tile is-child card" id="fyd">
            <div class="card-header">
              <span class="card-header-title">FYD</span>
            </div>
            <div class="columns is-desktop">
              <div class="column">
                <div class="content">
                  <h3>Tagging your data</h3>
                  <div class="card-label">
                    <span class="bulma-fa-mixin"
                      ><i class="fa-solid fa-book-atlas"></i> </span
                    >To be searchable or findable, data needs to be registered in a
                    database that later serves as a catalog, which is acessible through a
                    web portal.
                  </div>
                  <hr />
                </div>
              </div>
              <div class="column">
                <div class="content">
                  FYD creates a digital catalog for data sets. Compare this with a catalog
                  in a library with actual books. Each book title in the catalog has some
                  basic metadata. A unique letter code usually encapsulates this metadata
                  and additionally refers to it's location in the library. To help a user
                  locate a book this same code is labelled on the back of the book.<br />With
                  FYD users generate small metadata files from the FYD app and save this
                  with each data item that needs to be searchable. This metadata file tags
                  their data much like a book label. Our software indexes all these
                  tagfiles, registers the tag meta data and saves the location of each tag
                  to build a catalog automatically.
                  <div class="mytag" @click="$refs.taginfo.classList.add('is-active')">
                    <a>Read More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tile is-parent">
          <div class="tile is-child card" id="json">
            <div class="card-header">
              <span class="card-header-title">json</span>
            </div>
            <div class="columns is-desktop">
              <div class="column">
                <div class="content">
                  <h3>Documenting your data</h3>
                  <div class="card-label">
                    <span class="bulma-fa-mixin"><i class="fa-solid fa-tags"></i></span>
                    FYD tag files are small javascript object notation files (json). JSON
                    files are used to exchange data in diverse web and script
                    environments.
                  </div>
                  <hr />
                </div>
              </div>
              <div class="column">
                <div class="content">
                  Since FYD was developed at the Netherlands Institute for Neuroscience,
                  the key-value pairs within the tag files are oriented towards
                  neuroscience. However, these key-value paires constitute a very simple
                  data model, which can be easily be adapted and extended. The basic
                  key-value pairs are limited because they function as references to
                  documentation that users must add through a web portal. Users can also
                  add any amount of metadata to these tag files they might additionally
                  require.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref="fairinfo" class="modal anim">
        <div class="modal-background" @click="closemodal($refs.fairinfo)"></div>
        <div class="modal-card">
          <button
            class="modal-close is-large"
            aria-label="close"
            @click="closemodal($refs.fairinfo)"
          ></button>
          <section class="modal-card-body">
            <div class="content">
              To address this issue, efforts have been made to establish best practices
              and guidelines for data organization and documentation. For instance, some
              disciplines have developed domain-specific data standards or metadata
              schemas that aim to standardize the representation of data and associated
              information. These initiatives facilitate discussions among researchers,
              data specialists, and other stakeholders to develop common standards,
              protocols, and tools that enhance data usability and reusability.
              <br />
              In addition, data repositories and platforms often provide guidelines and
              templates for data submission, which force researchers to structure and
              document their data in a consistent manner. However the time and effort
              required for data reorganization may be considerable and is usually a task
              that is not undertaken untill forced to by journals on publication of a
              study.
              <br />
              Data curation and documentation should be seen as ongoing processes, and
              it's important for researchers to invest time in organizing and documenting
              their data from the outset. This proactive approach can save time and
              resources in the long run and improve the overall accessibility and
              usability of research data. Follow Your Data has been developed to stimulate
              researchers to start organizing their data from the very start of their
              research.
            </div>
          </section>
        </div>
      </div>
      <div ref="fydinfo" class="modal anim">
        <div class="modal-background" @click="closemodal($refs.fydinfo)"></div>
        <div class="modal-card">
          <button
            class="modal-close is-large"
            aria-label="close"
            @click="closemodal($refs.fydinfo)"
          ></button>
          <section class="modal-card-body">
            <div class="content">
              Some ways in which FYD can contribute to making data fair from the start
              include:
              <br /><br />
              1. Findability: FYD provides features that enable researchers to assign
              metadata or tags to their data, making it easier to search and locate
              specific datasets within the system.
              <br /><br />
              2. Accessibility: FYD is not an online archiving system. It does not provide
              controlled access to the data. This is where FYD really differs from other
              solutions. Application of FYD is not dependent on the underlying storage
              system and also independent of measures applied to provide controlled access
              to authorized users. FYD only has read access to the data enabling it to
              catalog the associated metadata and the urls to data objects.
              <br /><br />
              3. Interoperability: FYD can potentially offer data organization structures
              and standards that promote interoperability among different datasets within
              the lab or institute. By following consistent data organization practices,
              researchers can enhance the potential for data integration and reuse.
              <br /><br />
              4. Reusability: With FYD's cataloging capabilities, researchers can document
              and describe their data in a standardized manner, making it more
              understandable and usable for others, including potential collaborators or
              future researchers.
            </div>
          </section>
        </div>
      </div>
      <div ref="taginfo" class="modal anim">
        <div class="modal-background" @click="closemodal($refs.taginfo)"></div>
        <div class="modal-card">
          <button
            class="modal-close is-large"
            aria-label="close"
            @click="closemodal($refs.taginfo)"
          ></button>
          <section class="modal-card-body">
            <div class="content">
              With Follow Your Data, users are required to save a small metadata tag file
              together with their data for every experimental session. FYD indexes these
              tag files and updates a record of their location immediately or on a daily
              basis in a MySql database. So users can change, move, delete and copy files
              and folders as they please. As long as they keep the tag files together with
              their data, the database keeps a consistent set of links to the data.<br />
              <div class="mytag">
                <a href="https://github.com/Herseninstituut/FYD_Matlab" target="blank"
                  ><i class="fa-brands fa-github"></i> FYD-Matlab</a
                ><br />
                <a href="https://github.com/Herseninstituut/FYD_Python" target="blank"
                  ><i class="fa-brands fa-github"></i> FYD-Python</a
                >
              </div>
              Json tagfiles can be made with the MySql client software we provide on our
              public github pages:<br />
              The tag file users save has a specific format and name (ID_session.json).
              The ID part should be the name of the subject concatenated with the date and
              a session number to insure that every session file has a unique ID. This is
              automatically generated for you when you use one of the user interfaces. A
              json file should always contain _session after its’ ID, this makes the json
              file traceable by the follow your data system.<br />
              Here is a screenshot to give an impression of the userinterface we provide.
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>
  <div class="footer">
    <div class="content">
      Copyright © 2022.
      <br />Netherlands Institute for Neuroscience, <br />Meibergdreef 47, <br />The
      Netherlands. <br />Contact : C. van der Togt:
      <a href="mailto: c.vandertogt@nin.knaw.nl">Email</a>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const fairinfo = ref(0);
const taginfo = ref(1);
const fydinfo = ref(2);

// function showmodal(Objstr) {
//   // console.log(Objstr)
//   const Obj = this.$refs[Objstr];
//   Obj.classList.add('is-active')
// }

function closemodal(Obj) {
  Obj.classList.remove('is-active');
}
</script>

<style lang="scss">
@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;600;700&display=swap');
// @import url('../node_modules/bulma/css/bulma.css');

/* @function invert($color, $amount: 100%) {
  $inverse: change-color($color, $hue: hue($color) + 180);
  @return mix($inverse, $color, $amount);
} */
//$secondary: scale-color(invert($primary), $lightness: 10%);

$background: #ffffff;
$link: #cc93ba;
$primary: #977440;
// $secondary: #687684;
$secondary: rgb(45, 136, 177);
$label: #b4e1a8;

$modal-background-background-color: rgba(0, 0, 0, 0.5);
//$tablet: 800px;

//@import "../node_modules/bulma/sass/components/navbar.sass";

@import '../node_modules/bulma/sass/utilities/_all.sass';
@import '../node_modules/bulma/sass/base/_all.sass';
@import '../node_modules/bulma/sass/helpers/_all.sass';
@import '../node_modules/bulma/sass/components/card.sass';
@import '../node_modules/bulma/sass/grid/columns.sass';
@import '../node_modules/bulma/sass/grid/tiles.sass';
@import '../node_modules/bulma/sass/layout/footer.sass';
@import '../node_modules/bulma/sass/components/modal.sass';
//@import "../node_modules/bulma/sass/elements/container.sass";
//@import "../node_modules/bulma/sass/elements/title.sass";
//@import "../node_modules/bulma/sass/layout/hero.sass";
//@import "../node_modules/bulma/sass/layout/section.sass";

$box-shadow: 0.5rem 0.5rem 1rem 0.5rem rgba($black, 0.15);

body {
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  height: 100%;
}

hr {
  height: 10px;
  border-width: 0;
  border-radius: 5px;
  background-color: $primary;
  margin: 0;
  margin-top: 20px;
}

#back {
  background-image: url('./assets/lib.png');
  background-size: cover;
  //filter: brightness(70%);
  // -webkit-mask-image: linear-gradient(rgba(0, 0, 0, 0.5),black, rgba(0, 0, 0, 0.5)t);
  // mask-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}
#filt {
  background: rgba(0, 0, 0, 0.2);
}

#bottomfloat {
  font-size: x-small;
  position: absolute;
  right: 1%;
  bottom: 1%;
}

#text-logo {
  color: white;
  font-size: $size-2;
  position: relative;
  padding: 200px 0px 50px 0px;
  width: 100%;
  text-align: center;

  & #head {
    display: flex;
    justify-content: center;

    & img {
      padding-right: 10px;
    }
  }
}

a {
  color: $yellow;
  font-weight: $weight-medium;

  &:hover {
    color: $link;
  }
}

.navbar {
  font-size: larger;
  font-weight: bold;
}

.navbar-link {
  color: rgb(255, 255, 255);
  &:hover {
    background-color: transparent;
  }
}

.navbar-item {
  &.has-dropdown {
    & .navbar-dropdown {
      display: none;
    }

    &:hover {
      & .navbar-dropdown {
        display: block;
      }
    }
  }
}

section {
  background-color: $background;
}

.modal-card {
  width: 90%;
  font-weight: $weight-semibold;
}
.modal-card-body {
  padding: 25px;
}

.anim {
  animation: 0.5s openup;
  animation-timing-function: cubic-bezier(0.3, 0, 0.7, 1);
}

@keyframes openup {
  from {
    width: 0%;
  }

  to {
    width: 90%;
  }
}

.footer {
  // background: rgba(0, 0, 0, 0.0); /* Black background with 0.5 opacity */
  color: white;
  background-color: $primary;
  // font-size: larger;
}

.mytag {
  background-color: $secondary;
  text-align: center;
  float: right;
  border-radius: 15px;
  padding: 0px 10px 0px 10px;
}

.bulma-fa-mixin {
  color: $secondary;
  height: auto;
  float: right;
  font-size: 30px;
}

#main {
  // display: flex;
  // justify-content: center;
  max-width: 100%;
  // font-size: 16pt;
  // background-color:$secondary;
  // background-image: url(./assets/paper.jpg);
  // background-size: cover;
  // align-content: stretch;

  & .card {
    padding: 4% 4%;
    background-color: $background;
    margin: auto;
    margin-bottom: 50px;
    font-weight: $weight-semibold;
    // box-shadow: 0px 0px 10px $primary;

    &.content,
    h3 {
      font-size: $size-4;
      //  font-weight: $weight-semibold;
    }

    & .card-label {
      font-weight: $weight-bold;
      color: $primary;
      background-color: $label;
      overflow: auto;
      padding: 20px;
    }

    & .card-header-title {
      color: $primary;
      font-size: $size-3;
    }

    & .card-header {
      // background-color: $primary;
      text-transform: uppercase;
      box-shadow: none;
      //  padding-left: 1em;
      // width:20%;
      &::before {
        content: url(./assets/FYD.svg);
        display: inline-block;
        width: 30px;
        height: auto;
        margin: auto;
      }
    }
  }
}
</style>
